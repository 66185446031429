<template>
	<v-card outlined :color="`blue lighten-5`" class="h-100 d-flex flex-column">
		<v-card-title>
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<v-icon class="mr-2" color="cyan">mdi-format-list-text</v-icon>
			<div class="my-auto fw-600 text-capitalize fs-18">Project Activity</div>
			<v-spacer></v-spacer>
		</v-card-title>
		<v-card-text class="flex-grow-1">
			<div style="height: 400px" class="overflow-auto pr-5">
				<v-timeline dense>
					<v-timeline-item color="blue darken-4" small v-for="(log, index) in logData" :key="index">
						<div class="tab-card pa-3 mb-3 border tab-custom-data">
							<div class="d-flex mb-2 fw-500">
								<div class="">
									<span class="fw-600">{{ log.module }}</span>
								</div>

								<v-spacer></v-spacer>
								<div class="date d-flex align-items-center">
									<v-icon class="mdi-16px mr-1 primary--text">mdi-calendar-clock-outline</v-icon>
									<span v-if="log && log.added_at">Date : {{ formatDateTime(log.added_at) }}</span>
								</div>
							</div>
							<div class="d-flex mb-2 fw-500">
								<span class="fw-600 primary--text" v-if="log && log.display_name"
									>{{ log.display_name }}
								</span>
								<span v-if="log && log.description">{{ log.description }}</span>

								<!-- <span class="fw-600 ml-1" v-if="log && log.module">
									{{ log.module }} <v-icon class="mdi-16px">mdi-open-in-new</v-icon></span
								>
								to New. -->
								<v-spacer></v-spacer>
								<div class="date d-flex align-items-center" v-if="log && log.ip">
									<span v-if="log && log.ip">Ip Address: {{ log.ip }}</span>
								</div>
							</div>
						</div>
						<!-- <ActivityTab :open_details="false" :log="logs" title="Lead"></ActivityTab> -->
					</v-timeline-item>
				</v-timeline>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>
// import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import dashboardMixin from "@/core/mixins/dashboard.listing.mixin.js";
export default {
	name: "ProjectActivityList",
	mixins: [dashboardMixin],
	data() {
		return {
			filter: "This Month",
			countLoading: false,
			filterStatusList: [
				"This Week",
				"Next Week",
				"This Month",
				"Next Month",
				"This Quarter",
				"Next Quarter",
			],
			lists: [
				{
					id: 340,
					company: "Business Thrust Pvt Ltd",
					logo: this.$assetURL("media/logos/bthrust.png"),
					urls: "www.bthrust.com",
					cost: 0,
					status: 1,
					status_text: "Active",
					target_google: "Google Singapore",
					ranking_data: null,
					month_passed: 0,
					to_customer: {
						id: 4,
						type: 1,
						top_3: 5,
						top_5: 15,
						seo_id: 340,
						top_10: 65465,
						top_20: 52541,
						top_50: 116,
						top_100: 894,
						project_id: 302,
						kpi_6_month_top_3: 128,
						kpi_6_month_top_5: 1053,
						kpi_6_month_top_10: 8535,
						kpi_6_month_top_20: 65,
						kpi_6_month_top_50: 45,
						kpi_6_month_top_100: 25635,
					},
					to_delhi: {
						id: 5,
						type: 2,
						top_3: 25,
						top_5: 0,
						seo_id: 340,
						top_10: 0,
						top_20: 0,
						top_50: 0,
						top_100: 0,
						project_id: 302,
						kpi_6_month_top_3: 10,
						kpi_6_month_top_5: 0,
						kpi_6_month_top_10: 0,
						kpi_6_month_top_20: 0,
						kpi_6_month_top_50: 0,
						kpi_6_month_top_100: 0,
					},
					team_lead: {
						id: 45,
						role: 8,
						profile_img: null,
						display_name: "team lead(seo)",
					},
				},
				{
					id: 340,
					company: "Business Thrust Pvt Ltd",
					logo: this.$assetURL("media/logos/bthrust.png"),
					urls: "www.bthrust.com",
					cost: 0,
					status: 2,
					status_text: "Hold",
					target_google: "Google Singapore",
					ranking_data: null,
					month_passed: 0,
					to_customer: {
						id: 4,
						type: 1,
						top_3: 5,
						top_5: 15,
						seo_id: 340,
						top_10: 65465,
						top_20: 52541,
						top_50: 116,
						top_100: 894,
						project_id: 302,
						kpi_6_month_top_3: 128,
						kpi_6_month_top_5: 1053,
						kpi_6_month_top_10: 8535,
						kpi_6_month_top_20: 65,
						kpi_6_month_top_50: 45,
						kpi_6_month_top_100: 25635,
					},
					to_delhi: {
						id: 5,
						type: 2,
						top_3: 25,
						top_5: 0,
						seo_id: 340,
						top_10: 0,
						top_20: 0,
						top_50: 0,
						top_100: 0,
						project_id: 302,
						kpi_6_month_top_3: 10,
						kpi_6_month_top_5: 0,
						kpi_6_month_top_10: 0,
						kpi_6_month_top_20: 0,
						kpi_6_month_top_50: 0,
						kpi_6_month_top_100: 0,
					},
					team_lead: {
						id: 45,
						role: 8,
						profile_img: null,
						display_name: "team lead(seo)",
					},
				},
			],
			logData: [
				{
					id: 7537,
					module: "Project",
					mid: 455,
					description: "Project #BT-0000025 Updated",
					ip: "103.107.92.123",
					added_by: 17,
					added_at: "2024-01-08 19:30:47",
					device: "WebKit",
					device_id: null,
					platform: "Windows",
					platform_version: "10.0",
					browser: "Chrome",
					browser_version: "120.0.0.0",
					robot: null,
					languages: '["en-us", "en"]',
				},
				{
					id: 7138,
					module: "Project",
					mid: 455,
					description: "Project #BT-0000025 reminder Updated",
					ip: "103.107.92.123",
					added_by: 17,
					added_at: "2024-01-05 13:30:13",
					device: "WebKit",
					device_id: null,
					platform: "Windows",
					platform_version: "10.0",
					browser: "Chrome",
					browser_version: "120.0.0.0",
					robot: null,
					languages: '["en-us", "en"]',
				},
				{
					id: 7136,
					module: "Project",
					mid: 455,
					description: "Project #BT-0000025 reminder Updated",
					ip: "103.107.92.123",
					added_by: 17,
					added_at: "2024-01-05 13:29:35",
					device: "WebKit",
					device_id: null,
					platform: "Windows",
					platform_version: "10.0",
					browser: "Chrome",
					browser_version: "120.0.0.0",
					robot: null,
					languages: '["en-us", "en"]',
				},
				{
					id: 6927,
					module: "Project",
					mid: 455,
					description: "Project Task #TS-0000009 Created ",
					ip: "103.107.92.123",
					added_by: 17,
					added_at: "2024-01-04 18:11:04",
					device: "WebKit",
					device_id: null,
					platform: "Windows",
					platform_version: "10.0",
					browser: "Chrome",
					browser_version: "120.0.0.0",
					robot: null,
					languages: '["en-us", "en"]',
				},
			],
		};
	},
	methods: {
		filterData(item) {
			if (this.filter != item) {
				this.filter = item;
				this.countLoading = true;
				setTimeout(() => {
					this.countLoading = false;
				}, 2000);
			}
		},
	},
	components: {
		// ActivityTab,
	},
};
</script>
<style lang="scss" scoped>
.v-data-table__wrapper {
	tr:nth-child(even) {
		background: #fbf5eb;
	}
	tr {
		td {
			padding: 12px 12px !important;
		}
	}
}
</style>
